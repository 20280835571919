import axios from "axios";

export const getUrl=(path)=>{
  if(path.startsWith("/"))
    return API_HOST+path
  else
    return API_HOST+"/"+path
}

export const API_HOST= process.env.API_HOST || "https://uop-drive-jl635.ondigitalocean.app"

export const POST_LOGIN=getUrl("/api/login")
export const GET_YEARS=getUrl("/api/getYears")

export const GET_SEMESTERS=(year)=>getUrl("/api/getSemesters/")+year
export const GET_COURSES=(year,semester)=>getUrl(`/api/getCourses/${year}/${semester}`)
export const GET_SUB_TOPICS=(year,semester,course)=>getUrl(`/api/getSubTopics/${year}/${semester}/${course}`)
export const GET_ALL_FILES=(year,semester,course,subTopic)=>{
  if(!subTopic)
    return getUrl(`/api/getFiles/${year}/${semester}/${course}`)
  else
    return getUrl(`/api/getFiles/${year}/${semester}/${course}?subTopic=${subTopic}`)
}
export const GET_INDEXING_STATUS=getUrl("/api/indexingStatus")
export const GET_OTHER_DOCUMENTS=getUrl("/api/getOtherDocuments")

export const GET_SYNC=getUrl("/api/sync")

export const setAxiosAuth = (token) => {
  axios.defaults.headers.common["Authorization"] = token
    ? `Bearer ${token}`
    : "";
};
