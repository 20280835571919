import React from "react";
const selectedOptions={
  year:null,
  semester:null,
  course:null,
  subTopic:null
}

export const DashboardContext = React.createContext({
  selectedOptions,
  setSelectedOptions: () => {}
})
